import { Dictionary } from "../../../entities/common";
import { toDictionaryByKey } from "../../utils/common";

export type PAT = {
    id: string;
    createdDate: string;
    scopes: Scope[];
    name: string;
    expirationDate: string;

    data?: string;
}

export type PATWithJwt = {
    pat: PAT;
    jwt: string;
}

export enum Scope {
    ResourceWrite = "resource:write",
    ResourceRead = "resource:read",
    ProjectWrite = "project:write",
    ProjectRead = "project:read",
    ChallengeRead = "challenge:read",
    ChallengeWrite = "challenge:write",
    IdeaRead = "idea:read",
    IdeaWrite = "idea:write",
    TaskRead = "task:read",
    TaskWrite = "task:write",
    UserRead = "user:read",
    MPPFileIntegration = "integration:mppfile",

    KeyDateRead = "keydate:read",
    KeyDateWrite = "keydate:write",
    ProgramRead = "program:read",
    ProgramWrite = "program:write",
    PortfolioRead = "portfolio:read",
    PortfolioWrite = "portfolio:write",
    TimeTrackingRead = "timetracking:read",
    TimeTrackingWrite = "timetracking:write",

    IssueRead = "issue:read",
    IssueWrite = "issue:write",
    RiskRead = "risk:read",
    RiskWrite = "risk:write",
    ChangeRequestRead = "changerequest:read",
    ChangeRequestWrite = "changerequest:write",
    ActionItemRead = "actionitem:read",
    ActionItemWrite = "actionitem:write",
    LessonLearnedRead = "lessonlearned:read",
    LessonLearnedWrite = "lessonlearned:write",
    KeyDecisionRead = "keydecision:read",
    KeyDecisionWrite = "keydecision:write",
    DependencyRead = "dependency:read",
    DependencyWrite = "dependency:write",
    SteeringCommitteeRead = "steeringcommittee:read",
    SteeringCommitteeWrite = "steeringcommittee:write",
    PurchaseOrderRead = "purchaseorder:read",
    PurchaseOrderWrite = "purchaseorder:write",
    InvoiceRead = "invoice:read",
    InvoiceWrite = "invoice:write",
    DeliverablesRead = "deliverables:read",
    DeliverablesWrite = "deliverables:write",
    IterationsRead = "iterations:read",
    IterationsWrite = "iterations:write"
}

export type ScopeGroup = {
    key: string;
    label: string;
    listLabel: string;
    description?: string;
    scopes: { read: Scope, write?: Scope }
}

export const scopeGroups: ScopeGroup[] = [
    {
        key: "ActionItem",
        label: "Action Items",
        listLabel: "action items",
        scopes: { read: Scope.ActionItemRead, write: Scope.ActionItemWrite }
    },
    {
        key: "challenges",
        label: "Business Challenge Details",
        listLabel: "business challenges",
        scopes: { read: Scope.ChallengeRead, write: Scope.ChallengeWrite }
    },
    {
        key: "ChangeRequest",
        label: "Change Requests",
        listLabel: "change requests",
        scopes: { read: Scope.ChangeRequestRead, write: Scope.ChangeRequestWrite }
    },
    {
        key: "Deliverables",
        label: "Deliverables",
        listLabel: "deliverables",
        scopes: { read: Scope.DeliverablesRead, write: Scope.DeliverablesWrite }
    },
    {
        key: "Dependency",
        label: "Dependencies",
        listLabel: "dependencies",
        scopes: { read: Scope.DependencyRead, write: Scope.DependencyWrite }
    },
    {
        key: "idea",
        label: "Idea Details",
        listLabel: "ideas",
        scopes: { read: Scope.IdeaRead, write: Scope.IdeaWrite }
    },
    {
        key: "Invoices",
        label: "Invoices",
        listLabel: "invoices",
        scopes: { read: Scope.InvoiceRead, write: Scope.InvoiceWrite }
    },
    {
        key: "Issue",
        label: "Issues",
        listLabel: "issues",
        scopes: { read: Scope.IssueRead, write: Scope.IssueWrite }
    },
    {
        key: "iteration",
        label: "Iterations",
        listLabel: "iterations",
        scopes: { read: Scope.IterationsRead, write: Scope.IterationsWrite }
    },
    {
        key: "keyDate",
        label: "Key Dates",
        listLabel: "key dates",
        scopes: { read: Scope.KeyDateRead, write: Scope.KeyDateWrite }
    },
    {
        key: "KeyDecision",
        label: "Key Decision",
        listLabel: "key decisions",
        scopes: { read: Scope.KeyDecisionRead, write: Scope.KeyDecisionWrite }
    },
    {
        key: "LessonLearned",
        label: "Lessons Learned",
        listLabel: "lessons learned",
        scopes: { read: Scope.LessonLearnedRead, write: Scope.LessonLearnedWrite }
    },
    {
        key: "portfolio",
        label: "Portfolio Details",
        listLabel: "portfolios",
        scopes: { read: Scope.PortfolioRead, write: Scope.PortfolioWrite }
    },
    {
        key: "program",
        label: "Program Details",
        listLabel: "programs",
        scopes: { read: Scope.ProgramRead, write: Scope.ProgramWrite }
    },
    {
        key: "project",
        label: "Project Details",
        listLabel: "projects",
        scopes: { read: Scope.ProjectRead, write: Scope.ProjectWrite }
    },
    {
        key: "PurchaseOrders",
        label: "Purchase Orders",
        listLabel: "purchase orders",
        scopes: { read: Scope.PurchaseOrderRead, write: Scope.PurchaseOrderWrite }
    },
    {
        key: "Risk",
        label: "Risks",
        listLabel: "risks",
        scopes: { read: Scope.RiskRead, write: Scope.RiskWrite }
    },
    {
        key: "resource",
        label: "Resources & Calendars",
        listLabel: "resources",
        scopes: { read: Scope.ResourceRead, write: Scope.ResourceWrite }
    },
    {
        key: "SteeringCommittee",
        label: "Steering Committees",
        listLabel: "steering committees",
        scopes: { read: Scope.SteeringCommitteeRead, write: Scope.SteeringCommitteeWrite }
    },
    {
        key: "task",
        label: "Tasks",
        listLabel: "tasks",
        scopes: { read: Scope.TaskRead, write: Scope.TaskWrite }
    },
    {
        key: "timeTracking",
        label: "Time Tracking",
        listLabel: "time tracking",
        scopes: { read: Scope.TimeTrackingRead, write: Scope.TimeTrackingWrite }
    },
    {
        key: "user",
        label: "Users",
        listLabel: "users",
        scopes: { read: Scope.UserRead }
    },
]

export const scopeGroupsMap: Dictionary<ScopeGroup> = toDictionaryByKey(scopeGroups, "key"); 
export const ScopeGroupByScopeMap: Dictionary<string> = Object.values(scopeGroups).reduce<Dictionary<string>>(
    (acc, group) => { 
        acc[group.scopes.read] = group.key;
        if(group.scopes.write){
            acc[group.scopes.write] = group.key;
        }
        return acc;
    }, {});

export const PATApiPath = '/api/user/pat';