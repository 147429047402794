import {
    CheckboxVisibility, CommandBar, DefaultButton, DetailsListLayoutMode, IColumn, ICommandBarItemProps, IDetailsHeaderProps,
    IRenderFunction, ITooltipHostProps, Selection, Sticky,
    StickyPositionType, TooltipHost
} from "office-ui-fabric-react";
import * as React from "react";
import { connect } from "react-redux";
import { Dictionary, SortDirection } from "../../../entities/common";
import { get, remove } from "../../../fetch-interceptor";
import { SortService } from "../../../services/SortService";
import { SourceType, SourceTypeMap } from "../../../store/ExternalEpmConnectStore";
import { nameof } from "../../../store/services/metadataService";
import { defaultCatch } from "../../../store/utils";
import { IOrderBy } from "../../../store/views";
import DetailsListWrapper from "../../common/DetailsListWrapper";
import RemoveDialog from "../../common/RemoveDialog";
import { SectionControlPlaceholder } from "../../common/sectionsControl/SectionPlaceholder";
import { ControlSpiner } from "../../common/Spinner";
import { FormatDate, FormatDateTime, notUndefined, toDateTime } from "../../utils/common";
import { PAT, PATApiPath, Scope, ScopeGroupByScopeMap, scopeGroups, scopeGroupsMap } from "./common";
import CreationPanel, { PublicApiType } from "./PATCreationPanel";
import { ALL_SCOPES, isFullAccess, isMPPFileIntegrationAccess } from "./ScopesSelect";
import { ApplicationState } from "../../../store";
import { isInReadonlyMode } from "../../../store/User";

type ScopesInfo =
    {
        noName: string[];
        read: string[];
        readWrite: string[];
    }

type OwnProps = {
    availableApiTypes: PublicApiType[];
};

type StateProps = {
    isReadonlyMode: boolean;
};

type ActionProps = {
    defaultCatch: (error: any) => void;
};

type Props = OwnProps & StateProps & ActionProps;

type State = {
    tokens: PAT[];
    selectedCount: number;
    showRemoveDialog: boolean;
    createTokenType?: PublicApiType;
    orderBy: IOrderBy;
    columns: IColumn[];
    isLoading: boolean;
}

export class PATList extends React.Component<Props, State> {
    private _selection: Selection;

    constructor(props: Props) {
        super(props);
        const orderBy = {
            fieldName: nameof<PAT>("name"),
            direction: SortDirection.ASC
        }

        this.state = {
            tokens: [],
            selectedCount: 0,
            showRemoveDialog: false,
            orderBy: orderBy,
            columns: this._buildColumns(orderBy),
            isLoading: false
        }

        this._selection = new Selection({
            onSelectionChanged: () => this.setState({
                selectedCount: this._selection.getSelectedCount(),
            }),
            getKey: _ => (_ as PAT).id
        });
    }

    componentDidMount() {
        get<PAT[]>(PATApiPath)
            .then(_ => this.setState({ tokens: _ }))
            .catch(this.props.defaultCatch)
            .finally(() => this.setState({ isLoading: false }));

        this.setState({ isLoading: true });
    }

    public render() {
        const { tokens, selectedCount, columns, isLoading } = this.state;
        if (isLoading) {
            return <ControlSpiner isLoading={isLoading} />;
        }

        return <>
            {
                !tokens.length
                    ? <SectionControlPlaceholder
                        key="no-data"
                        title="Personal access tokens"
                        description={this._getAccessTokenDescription()}>
                        {this._getNewButtons().map(_ => <DefaultButton {..._} iconProps={{ iconName: "Add" }} />)}
                    </SectionControlPlaceholder>
                    : <>
                        <div key="list" className={`subentities-list pat-list`}>
                            <CommandBar className="header-command-bar" items={this._getCommandItems(selectedCount)} />
                            <DetailsListWrapper
                                layoutMode={DetailsListLayoutMode.justified}
                                items={tokens}
                                columns={columns}
                                selection={this._selection}
                                checkboxVisibility={CheckboxVisibility.always}
                                onColumnHeaderClick={this._onColumnHeaderClick}
                                onRenderDetailsHeader={this._onRenderDetailsHeader}
                            />
                        </div>
                        {
                            this.state.showRemoveDialog && <RemoveDialog
                                onClose={() => this.setState({ showRemoveDialog: false })}
                                onComplete={this._onRemovePAT}
                                dialogContentProps={{
                                    title: `Delete Token${selectedCount > 1 ? "s" : ""}`,
                                    subText: `Are you sure you want to delete ${selectedCount} Token${selectedCount > 1 ? "s" : ""}?`
                                }}
                                confirmButtonProps={{ text: "Delete" }} />
                        }
                    </>
            }
            {this.state.createTokenType != null && <CreationPanel publicApiType={this.state.createTokenType} onDismiss={this._onCreationPanelDismiss} />}
        </>;
    }

    private _onRemovePAT = () => {
        const ids = this._selection.getSelection().map(_ => _ as PAT).map(_ => _.id);
        remove(PATApiPath, { ids })
            .then(() => {
                const { tokens } = this.state;
                this.setState({ tokens: tokens.filter(_ => !~ids.indexOf(_.id)) })
            })
            .catch(this.props.defaultCatch);

        this.setState({ showRemoveDialog: false });
    }

    private _onRenderDetailsHeader(props: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />
                })}
            </Sticky>
        );
    }

    private _onAddClick = (type: PublicApiType) => {
        this.setState({ createTokenType: type });
    }

    private _onCreationPanelDismiss = (token?: PAT) => {
        if (token) {
            const { tokens, orderBy } = this.state;
            this.setState({
                tokens: tokens.concat([token]).sort(SortService.getSimpleComparer(orderBy))
            });
        }

        this.setState({ createTokenType: undefined });
    }

    private _getCommandItems = (selectedCount: number): ICommandBarItemProps[] => {
        const { isReadonlyMode } = this.props;
        const createButtons = this._getNewButtons();

        return [
            createButtons.length === 1
                ? {
                    ...createButtons[0],
                    iconProps: { iconName: "Add" },
                    disabled: isReadonlyMode
                }
                : {
                    key: "new-token",
                    text: "New Token",
                    iconProps: { iconName: "Add" },
                    disabled: isReadonlyMode,
                    subMenuProps: {
                        items: createButtons
                    }
                },
            {
                key: "delete",
                text: "Delete",
                disabled: selectedCount === 0 || isReadonlyMode,
                iconProps: { iconName: "Delete" },
                onClick: () => this.setState({ showRemoveDialog: true })
            }
        ];
    }

    private _getNewButtons() {
        const { availableApiTypes, isReadonlyMode } = this.props;
        return [
            availableApiTypes.includes(PublicApiType.general) && !isReadonlyMode
                ? {
                    key: "full-new-token",
                    text: "New API Token",
                    onClick: () => this._onAddClick(PublicApiType.general)
                } : undefined,
            availableApiTypes.includes(PublicApiType.integration) && !isReadonlyMode
                ? {
                    key: "mppfile-new-token",
                    text: `New ${SourceTypeMap[SourceType.MPPFile]} Token`,
                    onClick: () => this._onAddClick(PublicApiType.integration)
                } : undefined
        ].filter(notUndefined);
    }

    private _getAccessTokenDescription(): string | undefined {
        const isPublicApiTokenAvaliable = this.props.availableApiTypes.includes(PublicApiType.general);
        const isMppIntegrationTokenAvaliable = this.props.availableApiTypes.includes(PublicApiType.integration);
        const description = "Create user-specific authentication tokens that can be used to access";

        if (isPublicApiTokenAvaliable && isMppIntegrationTokenAvaliable) {
            return `${description} PPM Express Public API or Project Desktop data.`;
        }

        if (isPublicApiTokenAvaliable) {
            return `${description} PPM Express Public API.`;
        }

        if (isMppIntegrationTokenAvaliable) {
            return `${description} your Project Desktop data.`;
        }
        return undefined;
    }

    private _onColumnHeaderClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => {
        if (!column) {
            return;
        }

        const orderBy = SortService.getColumnOrderBy(column, this.state.orderBy);
        this.setState({
            orderBy: orderBy,
            tokens: this.state.tokens.map(_ => _).sort(SortService.getSimpleComparer(orderBy,
                orderBy.fieldName === nameof<PAT>("scopes")
                    ? this._getScopeText
                    : orderBy.fieldName === "Status"
                        ? this._getIsActive
                        : undefined)),
            columns: this._buildColumns(orderBy)
        });
    }

    private _buildColumns = (orderBy: IOrderBy): IColumn[] => {
        return [
            {
                key: "name",
                name: "Name",
                fieldName: nameof<PAT>("name"),
                minWidth: 150,
                maxWidth: 400
            },
            {
                key: "status",
                name: "Status",
                fieldName: "Status",
                minWidth: 150,
                maxWidth: 150,
                onRender: (item: PAT) => {
                    const active = this._getIsActive(item);
                    return <div className="pat-status">
                        <div className={`align-center status ${active ? 'Active' : 'Inactive'}`}>
                            {active ? 'Active' : 'Expired'}
                        </div>
                    </div>;
                }
            },
            {
                key: "created-date",
                name: "Created Date",
                fieldName: nameof<PAT>("createdDate"),
                minWidth: 150,
                maxWidth: 150,
                onRender: (item: PAT) => {
                    return FormatDateTime(item.createdDate);
                }
            },
            {
                key: "expiration-date",
                name: "Expiration Date",
                fieldName: nameof<PAT>("expirationDate"),
                minWidth: 150,
                maxWidth: 150,
                onRender: (item: PAT) => {
                    return FormatDate(item.expirationDate);
                }
            },
            {
                key: "scopes",
                name: "Scopes",
                fieldName: nameof<PAT>("scopes"),
                minWidth: 250,
                isMultiline: true,
                onRender: (item: PAT) => {
                    const text = this._getScopeText(item);
                    const info = this._getPatScopesInfo(item);
                    return <div title={text}>
                        <div>{info!.noName.join(', ')}</div>
                        {info!.read.length > 0 && <div><b>Read: </b>{info?.read.join(', ')}</div>}
                        {info!.readWrite.length > 0 && <div><b>Read & write: </b>{info?.readWrite.join(', ')}</div>}
                    </div>
                }
            }
        ].map(_ => ({
            ..._,
            isResizable: true,
            isSorted: _.fieldName === orderBy.fieldName,
            isSortedDescending: _.fieldName === orderBy.fieldName && orderBy.direction === SortDirection.DESC
        }));
    }

    private _getScopeText(item: PAT) {
        const info = this._getPatScopesInfo(item);
        return [info?.noName.join(', '),
        "Read & write: " + info?.readWrite.join(', '),
        "Read: " + info?.read.join(', ')]
            .join("\n");
    }

    private _getPatScopesInfo(item: PAT): ScopesInfo {
        if (isMPPFileIntegrationAccess(item.scopes)) {
            return { noName: [`${SourceTypeMap[SourceType.MPPFile]} integration`], read: [], readWrite: [] };
        }
        else if (isFullAccess(item.scopes)) {
            return { noName: ["All"], read: [], readWrite: [] };
        }
        else {
            return this._getScopesInfo(ALL_SCOPES.filter(_ => item.scopes.includes(_)));
        }
    }

    private _getScopesInfo(scopes: Scope[]): ScopesInfo {
        const noName: string[] = [], read: string[] = [], readWrite: string[] = [];

        const grouppedScopes = scopes.reduce<Dictionary<string[]>>(
            (acc, scope) => {
                const groupKey = ScopeGroupByScopeMap[scope] ?? "noGroup";
                if(!acc[groupKey]){
                    acc[groupKey] = [];
                }
                acc[groupKey].push(scope);
                return acc;
            } , {})

        for (const [groupKey, groupScopes] of Object.entries(grouppedScopes)) {
            const group = scopeGroupsMap[groupKey];
            if (!group) { 
                groupScopes.forEach(_ => noName.push(_));
                continue;
            } 
            if (groupScopes.includes(group.scopes.read) 
                && group.scopes.write && groupScopes.includes(group.scopes.write)) {
                readWrite.push(group.listLabel);
                continue;
            } 
            if(groupScopes.includes(group.scopes.read)) {
                read.push(group.listLabel);
            }
        }
        return { 
            noName: noName.sort((a, b) => a > b ? 1 : -1), 
            read: read.sort((a, b) => a > b ? 1 : -1), 
            readWrite: readWrite.sort((a, b) => a > b ? 1 : -1) 
        };
    }

    private _getIsActive(item: PAT) {
        return toDateTime(item.expirationDate)!.getTime() > new Date().getTime();
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        defaultCatch: defaultCatch(dispatch),
    };
}

export default connect(mapStateToProps, mergeActionCreators)(PATList);
