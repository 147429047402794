import { MessageBar, MessageBarType, Overlay, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { post } from "../../../fetch-interceptor";
import { Validator } from "../../../validation";
import { ItemCreation } from "../../common/ItemCreation";
import Spinner from "../../common/Spinner";
import { PAT, PATApiPath, PATWithJwt, Scope } from "./common";
import ScopesSelect, { MPPFileIntegrationScopes } from "./ScopesSelect";
import ExpirationDate, { getDate, defaultExpiration } from "./ExpirationDate";
import { defaultCatch } from "../../../store/utils";
import { connect } from "react-redux";
import { CopyButton } from "../../utils/copy";
import { SourceType, SourceTypeMap } from "../../../store/ExternalEpmConnectStore";
import { ApplicationState } from "../../../store";
import { UserState } from "../../../store/User";

export enum PublicApiType{
    general,
    integration
}
type ActionProps = {
    defaultCatch: (error: any) => void;
}
type OwnProps = {
    onDismiss: (pat?: PAT) => void,
    publicApiType: PublicApiType
}

type StateProps = {
    currentUser: UserState;
};

type Props = ActionProps & OwnProps & StateProps;

type State = {
    name?: string;
    scopes: Scope[];
    expirationDate: Date | undefined;

    isLoading: boolean;

    result?: PATWithJwt;
}

export class PATCreationPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            scopes: [],
            expirationDate: getDate(defaultExpiration)
        }
    }

    public render() {
        const { name, isLoading, result, scopes, expirationDate } = this.state;
        return <ItemCreation
            className="pat-creation-panel"
            onDismiss={this._onDismiss}
            header={{
                text: result ? "Token Created" : "Create Personal Access Token",
                secondaryText: result
                    ? "You have successfully added a new personal access token. Copy the token now!"
                    : this._getDescription(),
                showNameEditor: !result,
                nameEditorLabel: "Name",
                onChanged: ((newName: string) => {
                    this.setState({ name: newName });
                }),
                validator: validator,
                value: name
            }}
            commands={result
                ? [{
                    primary: true,
                    text: "Done",
                    onClick: this._onDismiss
                }]
                : [{
                    iconName: "Save",
                    primary: true,
                    text: "Generate Token",
                    onClick: this._onGenerateClick,
                    disabled: !validator.isValid(name) || !expirationDate || !this._getScopes().length
                }, {
                    text: "Cancel",
                    onClick: this._onDismiss
                }]}>
            {
                !result && <>
                    <div>
                        <h3>Expiration</h3>
                        <div className="expiration-date-container">
                            <ExpirationDate date={expirationDate} onChange={_ => this.setState({ expirationDate: _ })} />
                        </div>
                    </div>
                    {this.props.publicApiType === PublicApiType.general && <div className="scopes-select">
                        <p title="Access is managed by user permissions">
                            Created by: <b>{this.props.currentUser.name}</b>
                        </p>
                        <p>
                            <ScopesSelect scopes={scopes} onChange={_ => this.setState({ scopes: _ })} />
                        </p>
                    </div>}
                </>}
            {isLoading && <Overlay><Spinner /></Overlay>}
            {
                result && <>
                    <TextField
                        readOnly={true}
                        value={result.jwt}
                        onRenderSuffix={_ => <CopyButton text={result.jwt} />}
                        className="input-with-copy-button new-token-value" />
                    <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
                        Make sure you copy the above token now. We don't store it and you will not be able to see it again.
                    </MessageBar>
                </>
            }
        </ItemCreation>
    }

    private _onDismiss = () => {
        const { result } = this.state;
        this.props.onDismiss(result?.pat);
    }

    private _getDescription(): string{
        switch(this.props.publicApiType){
            case PublicApiType.general:
                return "Configure Token to access your data via Public API";
            case PublicApiType.integration:
                return `Configure Token to access your ${SourceTypeMap[SourceType.MPPFile]} data`;
            default:
                throw new Error("Invalid token Type");
        }
    }

    private _getScopes(): Scope[]{
        switch(this.props.publicApiType){
            case PublicApiType.general:
                return this.state.scopes;
            case PublicApiType.integration:
                return MPPFileIntegrationScopes;
            default:
                throw new Error("Invalid token Type");
        }
    }
    private _onGenerateClick = () => {
        const { name, expirationDate } = this.state;

        const pat = {
            name: name,
            expirationDate: expirationDate,
            scopes: this._getScopes()
        };

        post<PATWithJwt>(PATApiPath, pat)
            .then(_ => this.setState({ result: _ }))
            .catch(this.props.defaultCatch)
            .finally(() => this.setState({ isLoading: false }));

        this.setState({ isLoading: true })
    }
}

const validator = Validator.new().required().build();

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        currentUser: state.user,
    }
}
function mergeActionCreators(dispatch: any) {
    return {
        defaultCatch: defaultCatch(dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(PATCreationPanel);